.product {
	width: 340px;
	margin-top: 40px;
	text-decoration: none;
	@media only screen and (max-width: 620px) {
		width: 100%;
	}

	&:hover {
		@media only screen and (min-width: 768px) {
			& .quickadd {
				visibility: visible;
				opacity: 1;
			}
		}
	}

	&__image {
		height: 430px;
		background: rgb(245, 246, 247);
		position: relative;
		overflow: hidden;
		border-radius: 4px;

		&:after {
			background: rgba(black, 0.03);
			content: '';
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			position: absolute;
			z-index: 1;
		}

		img {
			object-fit: cover;
			object-position: center;
			width: 100%;
			height: 100%;
			transition: all 0.5s cubic-bezier(0.55, 0, 0.1, 1);
		}
		&:hover {
			img {
				transform: scale(1.05);
			}
		}

		.quickadd {
			width: 90%;
			height: 50px;
			position: absolute;
			background: white;
			z-index: 100;
			bottom: 10px;
			left: 0;
			right: 0;
			margin: auto;
			visibility: hidden;
			opacity: 0;
			transition: all 0.3s ease;
			display: flex;
			justify-content: center;
			align-items: center;
			color: #808284;
			font-weight: 100;
			font-size: 13px;
			cursor: pointer;
			transition: all 0.6s cubic-bezier(0.55, 0, 0.1, 1);
		}
	}
	&__title {
		font-size: 16px;
		font-family: 'Sentinel';
		font-weight: 200;
		color: rgb(126, 126, 126);
		margin-top: 10px;
		padding: 0 6px;
	}
	&__color {
		font-size: 13px;
		font-weight: 200;
		color: rgb(126, 126, 126);
		margin-top: 4px;
		text-transform: capitalize;
		padding: 0 6px;
	}
	&__price {
		font-size: 12px;
		font-weight: 600;
		color: var(--primary__color);
		margin-top: 4px;
		text-transform: capitalize;
	}
}
