.footer {
	background: rgba(#ececec, 0.7);
	margin-top: 50px;
	position: relative;
	padding: 100px 0 30px;
	&__links {
		flex: 1;
		display: flex;
	}
	&__wrapper {
		display: flex;
	}

	&__copy {
		margin-top: 50px;
		text-align: center;
		font-weight: 300;
		color: #464646;
		font-size: 11px;
		opacity: 0.5;
	}

	&:after {
		height: 3px;
		width: 100%;
		position: absolute;
		top: 0;
		left: 0;
		z-index: 9;
		content: '';
		background: url('../../components/images/pattern.png');
	}

	&__logo {
		width: 230px;
		height: 100%;
	}
	&__newsleter {
		width: 300px;
		margin-left: 80px;

		&__title {
			font-size: 14px;
			opacity: 0.5;
			color: #111;
		}
		&__field {
			margin-top: 10px;
			color: #4c4c4b;
			line-height: 1.5;
			width: 100%;
			height: 42px;
			background: darken(#f2f2f2, 10);
			padding: 10px;
			outline: none;
			border-radius: 4px;
			border: 1px solid #f2f2f2;
			-webkit-appearance: none;
			letter-spacing: 0.5px;
			font-size: 14px;
		}
	}
	&__nav {
		flex: 1;
		display: flex;

		ul {
			list-style: none;
			flex: 1;
			font-size: 14px;
			padding-left: 0px;

			li {
				margin-bottom: 10px;
				font-weight: 300;
				font-family: 'Sentinel';
				color: #464646;
				white-space: nowrap;
				opacity: 0.5;

				&:first-child {
					margin-bottom: 18px;
					font-weight: 500;
					font-family: inherit;
					opacity: 1;
				}
			}
		}
	}
}

@media only screen and (max-width: 768px) {
	.footer {
		padding-top: 40px;
		&__links {
			padding: 0 10px;
		}
		&__wrapper,
		&__nav {
			flex-direction: column;
		}
		&__newsleter {
			width: 100%;
			margin: 40px 0px;
			order: -1;
		}
		&__logo {
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			height: 100%;
		}
	}
}
