.hero {
	height: 590px;
	background-color: #222;
	//background: #222 url('//images.ctfassets.net/2stk9dntpgwb/2q1CdDUxgc0amMkG4sAM8w/dc1e6a0d521c5876d15ce519464fd586/Header-squashed_3_.jpg') top center / cover;
	position: relative;

	&:before {
		height: 100%;
		width: 100%;
		position: absolute;
		bottom: 0;
		left: 0;
		z-index: 4;
		content: '';
		background: rgba(black, 0.1);
	}
	&:after {
		//background: url('https://assets.gumroad.com/assets/header_bar-bd3be3db872f96368507899b9f29f6e4acdd64c84dfc132c2f7366924c900b52.png') top center;
		height: 3px;
		width: 100%;
		position: absolute;
		bottom: 0;
		left: 0;
		z-index: 9;
		content: '';
		background: url('../../components/images/pattern.png');
	}

	&__message {
		height: 50px;
		width: 100%;
		background: #f1f1f1;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 12px;
		opacity: 0.5;
		color: #111;

		a {
			color: #111;
			opacity: 0.9;
			text-decoration: none;
			margin-left: 4px;
		}
	}
}
.video {
	object-fit: cover;
	object-position: center;
	width: 100%;
	height: 100%;
}
.product__list {
	display: grid;
	grid-gap: 1.8em;
	grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
}

.popular__categories,
.popular__loading {
	display: flex;
	list-style: none;
	justify-content: space-between;
	@media only screen and (max-width: 640px) {
		flex-wrap: nowrap;
		overflow-y: auto;
		height: calc(230px + 28px);
	}

	li {
		@media only screen and (max-width: 640px) {
			display: inline-block;
			min-width: 160px;
			margin-right: 4px;
		}
		width: 160px;
		height: 230px;
		position: relative;
		cursor: pointer;

		&:hover {
			img {
				transform: scale(1.05);
			}
		}

		figure {
			width: 100%;
			height: 100%;
			margin: 0;
			overflow: hidden;
			border-radius: 6px;
			background-color: #ccc;
		}
		& > div {
			position: absolute;
			left: 0;
			right: 0;
			margin: auto;
			bottom: -18px;
			background-color: darken(#f1f1f1, 8); //var(--primary__color);
			color: #222;
			display: flex;
			align-items: center;
			justify-content: center;
			z-index: 2;
			width: calc(100% - 40px);
			text-align: center;
			border-radius: 20px;
			font-size: 11px;
			font-weight: 500;
			letter-spacing: 0.6px;
			border: 2px solid white;
			text-transform: uppercase;
			height: 30px;
		}
		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
			object-position: center;
			transition: all 0.5s cubic-bezier(0.55, 0, 0.1, 1.2);
		}
	}
}

.popular__loading {
	z-index: 222;
	li {
		width: 160px;
		height: 230px;
		position: relative;
		border-radius: 6px;
		background-color: #ccc;
		position: relative;
		&::before {
			content: '';
			height: 100%;
			width: 100%;
			position: absolute;
			top: 0;
			left: -20px;
			background: #fff;
			opacity: 0.49;
			z-index: 40;
			-webkit-filter: blur(35px);
			filter: blur(35px);
			animation: placeHolderShimmer 0.65s cubic-bezier(0.445, 0.05, 0.55, 0.95)
				infinite alternate-reverse;
		}
		@media only screen and (max-width: 640px) {
			display: inline-block;
			min-width: 160px;
			margin-right: 4px;
		}

		& > div {
			position: absolute;
			left: 0;
			right: 0;
			margin: auto;
			bottom: -18px;
			background-color: darken(#f1f1f1, 8); //var(--primary__color);
			color: #222;
			z-index: 2;
			width: calc(100% - 40px);
			height: 30px;
			border-radius: 20px;

			border: 2px solid white;
		}
	}
}

.section {
	padding: 100px 0 0;

	&__title {
		font-size: 22px;
		font-family: 'Sentinel';
		font-weight: 100;
		margin-bottom: 28px;
		text-transform: uppercase;
		letter-spacing: 2px;
		@media only screen and (max-width: 640px) {
			font-size: 19px;
			letter-spacing: 1.6px;
		}
	}
}
////////mobile
@media only screen and (max-width: 768px) {
	.hero {
		height: 50vh;
	}
}

@keyframes placeHolderShimmer {
	0% {
		left: 100%;
	}

	100% {
		left: -20px;
	}
}
//https://d3gmm2xq16tvhv.cloudfront.net/spree/cmsimage/805/homepage_carousel_retina/Joan.jpg?1547759157
//https://d3gmm2xq16tvhv.cloudfront.net/spree/cmsimage/788/homepage_carousel_normal/BlackFirdayDesktop.jpg?1542731636
