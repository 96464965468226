.items {
	&__close {
		position: absolute;
		top: 6px;
		right: 10px;
		height: 16px;
		width: 16px;
		background-color: rgba(black, 0.2);
		border: none;
		border-radius: 20px;
		cursor: pointer;

		&:after,
		&:before {
			content: '';
			height: 60%;
			width: 2px;
			background-color: rgba(white, 0.8);
			position: absolute;
			left: 0;
			right: 0;
			top: 0;
			bottom: 0;
			margin: auto;
			transform: rotate(45deg);
		}
		&:before {
			transform: rotate(-45deg);
		}
	}
	&__image {
		height: 80px;
		width: 80px;
		background: #ccc;

		img {
			height: 100%;
			width: 100%;
			object-fit: cover;
			object-position: center;
		}
	}
	&__content {
		margin-left: 10px;
	}
	&__name {
		font-family: 'Sentinel';
		color: #464646;
		font-weight: 100;
		font-size: 18px;
		margin-bottom: 4px;
	}
	&__size {
		color: rgba(#464646, 0.5);
		font-weight: 100;
		font-size: 13px;
		margin-bottom: 2px;
	}
}

.cart {
	transition: all 0.6s cubic-bezier(0.55, 0, 0.1, 1);
	overflow: hidden;
	@media only screen and (max-width: 620px) {
		overflow: auto;
	}

	&__list {
		font-weight: 200;
		display: flex;
		justify-content: space-between;
		margin-bottom: 6px;
		font-size: 14px;
		color: #464646;
	}
	&__emptystate {
		height: 100%;
		width: 100%;
		justify-content: center;
		align-items: center;
		font-family: 'Sentinel';
		color: var(--primary__color);
		display: flex;
	}

	&__items {
		width: 100%;
		overflow-y: scroll;
		max-height: 400px;

		&::-webkit-scrollbar {
			height: 6px;
			width: 6px;
		}

		&::-webkit-scrollbar-button:end:increment,
		&::-webkit-scrollbar-button:start:decrement {
			background: transparent;
			display: none;
		}

		&::-webkit-scrollbar-track-piece:horizontal:start {
			border-radius: 4px 0 0 4px;
		}

		&::-webkit-scrollbar-track-piece:horizontal:end {
			border-radius: 0 4px 4px 0;
		}

		&::-webkit-scrollbar-thumb {
			background: rgba(9, 45, 66, 0.13);
			border-radius: 4px;
			display: block;
		}

		&::-webkit-scrollbar-track-piece {
			background: rgba(9, 45, 66, 0.08);
		}

		&__list {
			border-bottom: 1px dashed rgba(216, 216, 216, 0.6);
			margin-bottom: 10px;
			padding: 10px;
			display: flex;
			position: relative;
		}
	}

	&__cart {
		font-family: 'Sentinel';
		font-weight: 100;
		letter-spacing: 5px;
		text-align: center;
		text-transform: uppercase;
		font-size: 19px;
		color: #464646;
	}
	&__btn {
		margin: 18px 0 0;
		padding: 18px 0;
		background: var(--primary__color);
		color: white;
		text-decoration: none;
		text-align: center;
		border: none;

		&:disabled {
			opacity: 0.5;
		}
	}
	&__title {
		min-height: 60px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		border-bottom: 1px solid rgba(#d8d8d8, 0.6);
		font-size: 14px;
		font-weight: 300;
	}
	&__body {
		min-height: 60px;
		display: flex;
		//align-items: center;
		justify-content: space-between;
		border-bottom: 1px solid rgba(#d8d8d8, 0.6);
		padding: 40px 0;
		@media only screen and (max-width: 620px) {
			flex-direction: column;
		}
	}
	&__box {
		flex: 1;
		display: flex;
		flex-direction: column;

		&__pane {
			margin-top: 6px;
			padding: 10px 5px;
			border: 1px dashed rgba(#d8d8d8, 0.6);
			margin-right: 20px;
			display: flex;
			flex: 1;

			@media only screen and (max-width: 620px) {
				margin-right: 0px;
				min-height: 80px;
				max-height: 400px;
				align-items: center;
			}
		}
		&__pane__right {
			margin-top: 6px;
			padding: 20px;
			margin-left: 20px;
			display: flex;
			flex-direction: column;
			min-height: 400px;
			@media only screen and (max-width: 620px) {
				margin-left: 0px;
			}
		}
	}
	&__panel__loggedout {
		//border-bottom: 1px solid rgba(#d8d8d8, 0.6);
		margin: 63px 0;
		text-align: center;

		@media only screen and (max-width: 620px) {
			margin-top: 30px;
		}
	}
}

.h3 {
	font-family: 'Sentinel';
	font-weight: 100;
	letter-spacing: 5px;
	color: #464646;
	font-weight: 100;
}

////////////// menu

.menus {
	float: right;
	height: 100%;

	.cartCount {
		color: var(--primary__color); //rgb(126, 126, 126);
		font-size: 12px;
		height: 23px;
		min-width: 23px;
		position: absolute;
		right: 6px;
		border-radius: 100px;
		display: flex;
		align-items: center;
		justify-content: center;
		border: 1px solid var(--primary__color);
		z-index: 0;
		-webkit-font-smoothing: subpixel-antialiased;
	}

	ul {
		list-style: none;
		display: flex;
		flex: 1;
		height: 100%;
		li {
			margin-left: 0px;
			height: 100%;

			&.cartBtn {
				width: 100px;
				padding-right: 40px;
				display: flex;
				align-items: center;
				position: relative;
				transition: all 0.3s ease;
				&:hover {
					background-color: rgb(244, 244, 244);
					color: rgb(77, 77, 77);
				}

				& > button {
					width: 100%;
					height: 100%;
					position: absolute;
					padding-right: 40px;
					border: none;
					cursor: pointer;
					color: rgb(126, 126, 126);
					letter-spacing: 2px;
					font-size: 11px;
					text-transform: uppercase;
					font-weight: 500;
					display: flex;
					align-items: center;
					text-align: center;
					padding-left: 14px;
					z-index: 1;
					background-color: transparent;
					-webkit-font-smoothing: subpixel-antialiased;
				}
			}

			a {
				padding: 0 20px;
				align-items: center;
				display: flex;
				height: 100%;
				text-decoration: none;
				color: rgb(126, 126, 126);
				letter-spacing: 2px;
				font-size: 10px;
				text-transform: uppercase;
				font-weight: 500;
				transition: all 0.3s ease;
				-webkit-font-smoothing: subpixel-antialiased;

				@media only screen and (max-width: 768px) {
					margin-top: 1px;
					padding: 0 12px;
				}

				&:hover {
					background-color: rgb(244, 244, 244);
					color: rgb(77, 77, 77);
				}
			}
		}
	}
}
