.site__logo {
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 24px 0px;
}
@media only screen and (max-width: 768px) {
	.site__logo {
		text-align: center;
		display: flex;
		align-items: center;
		margin: 15px 0px;
	}
}
.container {
	max-width: 1100px;
	margin: 0 auto;
	width: calc(100% - 30px);
	height: 100%;
}
.header {
	background-color: var(--primary__color);
	display: flex;
	align-items: center;
	height: 36px;
	box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.03), 0 1px 1px rgba(0, 0, 0, 0.05);
	z-index: 5;
	position: relative;
}

.navigation {
	height: 100%;
	display: flex;
	z-index: 5;
	position: relative;
}
.link {
	color: #fff;
	text-decoration: none;
	position: relative;
	transition: all 0.3s ease;
	height: 100%;
	display: inline-flex;
	align-items: center;
	padding: 0 30px;
	text-transform: capitalize;
	border-bottom: 1px solid rgba(0, 0, 0, 0);
	font-size: 15px;

	&:hover {
		border-bottom: 1px solid rgba(0, 0, 0, 0.05);
		background-color: rgba(0, 0, 0, 0.05);

		& > .dropmenu {
			opacity: 1;
			visibility: visible;
			transform: translateY(0);
			z-index: 1;
		}
	}
}

.dropmenu {
	position: absolute;
	top: calc(100% + 0px);
	left: 0;
	right: 0;
	z-index: -1;
	transform: translateY(3px);
	opacity: 0;
	visibility: hidden;
	width: fit-content;
	min-width: 300px;
	cursor: default;
	box-shadow: 0 2px 4px 0 rgba(76, 76, 75, 0.1);
	background-color: hsla(0, 0%, 100%, 0.98);
	display: flex;
	box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.3);
	color: black;
	transition: all 0.3s ease;
}

.menu__list {
	list-style: none;
	display: inline-flex;
	height: 100%;
	align-items: center;
	position: relative;

	&--line {
		&::before {
			content: '';
			width: 1px;
			background: rgba(#fff, 0.4);
			height: 40%;
			right: 0;
			bottom: 0;
			top: 0;
			margin: auto;
			z-index: 1;
			position: absolute;
		}
	}
}

.menu__sub {
	list-style: none;
	background-color: rgba(0, 0, 0, 0.03);
	margin: 0;
	padding: 40px;
}

.menu__sub__items {
	min-height: 40px;
	display: flex;
	align-items: center;
	cursor: pointer;
	color: #a3a5a7;
	stroke: #a3a5a7;
	transition: all 0.3s ease;
	white-space: nowrap;

	&:hover {
		color: darken(#a3a5a7, 20);
	}
}

.menu__image {
	min-width: 250px;

	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
		object-position: center;
	}
}

.search__bar {
	height: 100%;
	width: 100%;
	position: relative;
	margin-left: 30px;
}
.search__field {
	height: 100%;
	width: 100%;
	background-color: rgba(0, 0, 0, 0.05);
	outline: none;
	transition: all 0.3s ease;
	border: none;
	-webkit-appearance: none;

	font-size: 16px;
	padding: 0 10px;
	color: rgba(#fff, 1);

	&:focus {
		background-color: rgba(0, 0, 0, 0.08);
	}

	&::placeholder {
		color: rgba(#fff, 0.4);
	}
}
.search__menu {
	position: absolute;
	top: 100%;
	width: 100%;
	height: 200px;
	background-color: white;
	color: #222;
	padding: 20px;
	border-radius: 4px;
}

.top__bar {
	height: 35px;
	box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.03), 0 1px 1px rgba(0, 0, 0, 0.05);
}
