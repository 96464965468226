#main {
	overflow-x: hidden;
	width: 100%;
	top: 0;
	//position: fixed;
}

.btn {
	font-weight: 200;
	font-style: normal;
	user-select: none;
	text-transform: uppercase;
	letter-spacing: 3px;
	border: 0;
	margin: 0;
	background-image: none;
	background-repeat: no-repeat;
	background-position: 0 0;
	font-size: 12px;
	text-decoration: none;
	color: #fff;
	cursor: pointer;
	text-align: center;
	padding: 14px 18px;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	display: inline-block;
	zoom: 1;
	margin-top: 25px;
	position: relative;
	&::before {
		transition: all 0.3s ease;
		opacity: 0;
	}
	&--primary {
		background-color: var(--primary__color);
	}

	&:hover {
		&::before {
			opacity: 1;
			content: '';
			top: 0;
			left: 0;
			position: absolute;
			background-color: rgba(black, 0.08);
			z-index: 1;
			height: 100%;
			width: 100%;
		}
	}
}

.product__video {
	height: 620px;
	background: rgb(245, 246, 247);
	overflow: hidden;
	object-fit: cover;
	object-position: center;
	margin-bottom: 30px;
}

.product__image {
	height: 620px;
	background: rgb(245, 246, 247);
	overflow: hidden;
	margin-bottom: 30px;
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;

	img {
		object-fit: cover;
		object-position: center;
		width: 100%;
		height: 100%;
		position: relative;
		z-index: 2;
	}
}

@media only screen and (max-width: 640px) {
	.product__image,
	.product__video {
		min-width: 86vw;
		margin-bottom: 0px;
		height: 50vh;
	}
}
