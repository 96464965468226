@font-face {
	font-family: 'gt-walsheim';
	font-display: swap;
	font-weight: 500;
	font-style: normal;
	src: url('./components/fonts/gt-walsheim/GT-Walsheim-Medium.woff2')
			format('woff2'),
		url('./components/fonts/gt-walsheim/GT-Walsheim-Medium.woff') format('woff');
}
//gt-Walsheim
@font-face {
	font-family: 'gt-walsheim';
	font-display: swap;
	font-weight: 300;
	font-style: normal;
	src: url('./components/fonts/gt-walsheim/GT-Walsheim-Regular.woff')
			format('woff'),
		url('./components/fonts/gt-walsheim/GT-Walsheim-Regular.woff2')
			format('woff2');
}

@font-face {
	font-family: 'gt-walsheim';
	font-display: fallback;
	font-weight: bold;
	font-style: normal;
	src: url('./components/fonts/gt-walsheim/GT-Walsheim-Bold.woff')
			format('woff'),
		url('./components/fonts/gt-walsheim/GT-Walsheim-Bold.woff2') format('woff2');
}

@font-face {
	font-family: 'cheltenham';
	font-display: swap;
	font-weight: 200;
	font-style: normal;
	src: url('./components/fonts/cheltenham/cheltenham-normal-200.woff2')
			format('woff2'),
		url('./components/fonts/cheltenham/cheltenham-normal-200.woff')
			format('woff');
}

@font-face {
	font-family: 'Sentinel';
	font-display: swap;
	font-weight: 300;
	font-style: normal;
	src: url('./components/fonts/Sentinel/Sentinel-Book.woff2') format('woff2'),
		url('./components/fonts/Sentinel/Sentinel-Book.woff') format('woff');
}

@font-face {
	font-family: 'Sentinel';
	font-display: swap;
	font-weight: 100;
	font-style: normal;
	src: url('./components/fonts/Sentinel/Sentinel-Light.woff2') format('woff2'),
		url('./components/fonts/Sentinel/Sentinel-Light.woff') format('woff');
}

$font-family-text: 'gt-walsheim';

html {
	box-sizing: border-box;
	//scroll-behavior: smooth;
}
*,
*::before,
*::after {
	box-sizing: inherit;
	outline: none;
}
body,
h1,
h2,
h3,
h4,
h5,
h6,
ul,
ol,
li,
p,
pre,
blockquote,
figure,
hr {
	margin: 0;
	padding: 0;
}
ul {
	list-style: none;
}
input,
textarea,
select,
button {
	color: inherit;
	font: inherit;
	letter-spacing: inherit;
}
embed,
iframe,
img,
object,
video {
	display: block;
	max-width: 100%;
}
table {
	table-layout: fixed;
	width: 100%;
}
[hidden] {
	display: none;
}

:root {
	--primary__color: #c83442;
}

body {
	min-height: 100%;
	display: flex;
	-webkit-box-orient: vertical;
	flex-direction: column;
	font-size: 100%;
	font-family: $font-family-text, Open Sans, Segoe UI, sans-serif;
	font-weight: 400;
	font-style: normal;
	-webkit-text-size-adjust: 100%;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-rendering: optimizeLegibility;
	-webkit-font-feature-settings: 'pnum';
	font-feature-settings: 'pnum';
	font-variant-numeric: proportional-nums;
	background: white; //lighten(rgb(247, 246, 243,), 2);

	&::-webkit-scrollbar {
		height: 6px;
		width: 6px;
	}

	&::-webkit-scrollbar-button:end:increment,
	&::-webkit-scrollbar-button:start:decrement {
		background: transparent;
		display: none;
	}

	&::-webkit-scrollbar-track-piece:horizontal:start {
		border-radius: 4px 0 0 4px;
	}

	&::-webkit-scrollbar-track-piece:horizontal:end {
		border-radius: 0 4px 4px 0;
	}

	&::-webkit-scrollbar-thumb {
		background: rgba(9, 45, 66, 0.13);
		border-radius: 4px;
		display: block;
	}

	&::-webkit-scrollbar-track-piece {
		background: rgba(9, 45, 66, 0.08);
	}

	::selection {
		background: rgba(97, 199, 198, 0.3);
	}

	::-moz-selection {
		background: rgba(97, 199, 198, 0.3);
	}
}
